import * as actionTypes from '../constants/action_types';
import {updateObject} from '../utilities';

const initialState = {
    isLoading: false,
    error: false,
    message: null
}

const setError = ( state, action ) => {
    return updateObject( state, {
        error: true,
        message: action.message
    });
};

const unsetError = ( state, action ) => {
    return updateObject( state, initialState);
};

const setIsLoading = ( state, action) => {
    return updateObject( state, {
        isLoading: action.isLoading
    });
};

const global = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.GLOBAL_SET_IS_LOADING: return setIsLoading(state, action);
      case actionTypes.GLOBAL_SET_ERROR:return setError(state, action);
      case actionTypes.GLOBAL_UN_SET_ERROR:return unsetError(state, action);
      default:
          return state;
    }
  };

  
export default global;