
import React, { useEffect } from 'react';
import './App.scss';
import { BrowserRouter, Switch, Route} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import * as actionsAuth from './store/actions/auth';
import {getLocalIp}  from './store/utilities';
import Loading from './views/pages/Loading';
import moment from 'moment';
import 'moment/locale/es';

const DefaultLayout = React.lazy(() => import('./layouts/DefaultLayout/DefaultLayout'));
moment.updateLocale('es', {
  monthsShort : ['Ene',  'Feb',  'Mar',  'Abr',  'May',  'Jun', 'Jul',  'Ago', 'Sep',  'Oct',  'Nov',  'Dic']
});

const App = (props)  => {
  
    const loading = () => (<div className="animate__animated animate__fadeIn h-100 "><Loading /></div>);
    const routes = (
      <Switch>
        <Route path="/" name="Home" render={propsLayout => <DefaultLayout {...propsLayout} />} />
      </Switch>
    );
    const dispatch = useDispatch();
    
    useEffect(() => {
      async function getParams(){
        try { 
          const refer = new URLSearchParams(window.location.search).get("refer");
          if(refer) {
            const ip = await getLocalIp();
            sessionStorage.setItem('refer',refer);
            sessionStorage.setItem("ip", ip);
            dispatch(actionsAuth.setRefer(refer));
            dispatch(actionsAuth.setIpAddress(ip));
          }else{
            throw new Error ('Not found refer in Params');
          }
        } catch (err) {
          dispatch(actionsAuth.authFail());
        }
      }
      getParams();
    },[]);

    return (
      <React.Suspense fallback={loading()}>
        <BrowserRouter>
          {routes}
        </BrowserRouter>
      </React.Suspense>
    )
}

export default App;
