const publicIp = require('public-ip');

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const getLocalIp = async () => {
    try {
        let ip = await publicIp.v4();
        return ip;
    } catch(error) {
        console.log(error);
        return "127.0.0.1";
    }
};