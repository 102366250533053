import * as actionTypes from '../constants/action_types';
import {updateObject} from '../utilities';

const initialState = {
    filters: {
        from_date: '',
        to_date: '',
        state: 'POR RENOVAR',
        keyword: '',
        searchKey:''
    },
    pagination: {
        page_number: 1,
        page_size: 7,
        total_number_records:7,
        total_pages:2
    },
    policies: [],
    policy: null,
    summary: {
        count_in_process: 0,
        total_in_process: 0,
        total_renewed: 0,
        count_renewed: 0,
        count_not_renewed: 0
    }
}

const setPendingPolicies = ( state, action) => {
    return updateObject( state, {
        policies: action.policies
    });
};

const setPagination = ( state, action) => {
    return updateObject( state, {
        pagination: action.pagination
    });
};

const setFilters = ( state, action) => {
    return updateObject( state, {
        filters: action.filters
    });
};

const setSummary = ( state, action) => {
    return updateObject( state, {
        summary: action.summary
    });
};

const setPolicyManage = ( state, action) => {
    return updateObject( state, {
        policy: action.policy
    });
};

const policy = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.POLICY_SET_PENDING:return setPendingPolicies(state, action);
      case actionTypes.POLICY_SET_PAGINATION:return setPagination(state, action);
      case actionTypes.POLICY_SET_FILTERS:return setFilters(state, action);
      case actionTypes.POLICY_SET_SUMMARY: return setSummary(state, action);
      case actionTypes.POLICY_SET_MANAGEMENT: return setPolicyManage(state, action);
      default:
          return state;
    }
  };

  
export default policy;