import { combineReducers } from 'redux';
import session from './session';
import global from './global';
import policy from './policy'
import budget from './budget';

const appReducers = combineReducers({
  session,
  global,
  policy,
  budget
});

const rootReducer = (state, action) => {
    let reducers = appReducers(state, action);
    return reducers;
};

export default rootReducer;
