import * as actionTypes from '../constants/action_types';

export const setBrokerCode = (broker_code) => {
    return {
        type: actionTypes.SESSION_SET_BROKER_CODE,
        broker_code
    }
}

export const setBrokerName = (broker_name) => {
    return {
        type: actionTypes.SESSION_SET_BROKER_NAME,
        broker_name
    }
}

export const setLastSession = (last_session) => {
    return {
        type: actionTypes.SESSION_SET_LAST_SESSION,
        last_session
    }
}

export const setRefer = (refer) => {
    return {
        type: actionTypes.SESSION_SET_REFER,
        refer
    }
}


export const setIpAddress = (ipAddress) => {
    return {
        type: actionTypes.SESSION_SET_IP_ADDRESS,
        ipAddress:ipAddress
    }
}

export const setAccessToken = (accessToken) => {
    return {
        type: actionTypes.SESSION_SET_ACCESS_TOKEN,
        accessToken
    };
};

export const authStart = () => {
    return {
        type: actionTypes.SESSION_AUTH_START
    };
};

export const authFail = () => {
    return {
        type: actionTypes.SESSION_AUTH_FAIL
    };
};

export const setReset = () => {
    return {
        type: actionTypes.SESSION_AUTH_RESET
    };
};

export const setLogout = () => {
    return {
        type: actionTypes.SESSION_AUTH_LOGOUT
    };
};

export const closeSession = () => {
    return async (dispatch) => {
        localStorage.clear();
        dispatch(setReset());
    }
}

