import * as actionTypes from '../constants/action_types';
import {updateObject} from '../utilities';

const initialState = {
    logout: false,
    accessToken: null,
    ipAddress: null,
    refer: null,
    broker_code: '',
    broker_name: '',
    last_session: '',
    error : false,
    isLogged: false
}

const authStart = ( state, action ) => {
    return updateObject( state, {
        isLogged: true
    });
};


const setBrokerCode = ( state, action) => {
    return updateObject( state, {
        broker_code: action.broker_code
    });
};

const setBrokerName = ( state, action) => {
    return updateObject( state, {
        broker_name: action.broker_name
    });
};

const setLastSession = ( state, action) => {
    return updateObject( state, {
        last_session: action.last_session
    });
};

const setRefer = ( state, action) => {
    return updateObject( state, {
        refer: action.refer
    });
};

const setAccessToken = ( state, action) => {
    return updateObject( state, {
        accessToken: action.accessToken
    });
};

const authFail = (state, action) => {
    return updateObject( state, {
        error: true
    });
};


const setLogout = (state, action) => {
    return updateObject(state, {
        logout:  !state.logout
    });
};

const setIpAddress = (state, action) => {
    return updateObject(state,{
        ipAddress:action.ipAddress
    })
}

const authReset = (state, action) => {
    return updateObject (initialState , {
        logout: true
    });
}

const session = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SESSION_SET_ACCESS_TOKEN: return setAccessToken(state, action);
      case actionTypes.SESSION_SET_IP_ADDRESS:return setIpAddress(state, action);
      case actionTypes.SESSION_SET_BROKER_CODE:return setBrokerCode(state, action);
      case actionTypes.SESSION_SET_BROKER_NAME:return setBrokerName(state, action);
      case actionTypes.SESSION_SET_LAST_SESSION:return setLastSession(state, action);
      case actionTypes.SESSION_SET_REFER:return setRefer(state, action);
      case actionTypes.SESSION_AUTH_START:return authStart(state, action);
      case actionTypes.SESSION_AUTH_FAIL:return authFail(state, action);
      case actionTypes.SESSION_AUTH_LOGOUT:return setLogout(state, action);
      case actionTypes.SESSION_AUTH_RESET:return authReset(state, action);
      default:
          return state;
    }
  };

  
export default session;

