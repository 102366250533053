export const GLOBAL_SET_IS_LOADING = 'GLOBAL_SET_IS_LOADING';
export const GLOBAL_SET_ERROR = 'GLOBAL_SET_ERROR';
export const GLOBAL_UN_SET_ERROR = 'GLOBAL_UN_SET_ERROR';
export const GLOBAL_SET_ERROR_AUTH = 'GLOBAL_SET_ERROR_AUTH';
export const GLOBAL_UN_SET_ERROR_AUTH = 'GLOBAL_UN_SET_ERROR_AUTH';

export const SESSION_SET_IP_ADDRESS =  'SESSION_SET_IP_ADDRESS';
export const SESSION_SET_ACCESS_TOKEN =  'SESSION_SET_ACCESS_TOKEN';
export const SESSION_SET_BROKER_CODE = 'SESSION_SET_BROKER_CODE';
export const SESSION_SET_BROKER_NAME = 'SESSION_SET_BROKER_NAME';
export const SESSION_SET_LAST_SESSION = 'SESSION_SET_LAST_SESSION';
export const SESSION_SET_REFER = 'SESSION_SET_REFER';
export const SESSION_AUTH_START = 'SESSION_AUTH_START';
export const SESSION_AUTH_FAIL = 'SESSION_AUTH_FAIL';
export const SESSION_AUTH_LOGOUT = 'SESSION_AUTH_LOGOUT';
export const SESSION_AUTH_RESET = 'SESSION_AUTH_RESET';

export const POLICY_SET_PENDING = "POLICY_SET_PENDING";
export const POLICY_SET_PAGINATION = "POLICY_SET_PAGINATION";
export const POLICY_SET_FILTERS = "POLICY_SET_FILTERS";
export const POLICY_SET_SUMMARY = "POLICY_SET_SUMMARY";
export const POLICY_SET_MANAGEMENT = "POLICY_SET_MANAGEMENT";

export const BUDGET_SET_POLICY = "BUDGET_SET_POLICY";
export const BUDGET_SET_CONTACT = "BUDGET_SET_CONTACT";
export const BUDGET_SET_VEHICLE = "BUDGET_SET_VEHICLE";
export const BUDGET_SET_VEHICLE_ERROR = 'BUDGET_SET_VEHICLE_ERROR';
export const BUDGET_SET_POLICY_NOT_FOUND_ERROR = 'BUDGET_SET_POLICY_NOT_FOUND_ERROR';
export const BUDGET_SET_IVA = "BUDGET_SET_IVA";
export const BUDGET_SET_COEXISTENCE = "BUDGET_SET_COEXISTENCE";
export const BUDGET_SET_SEND = "BUDGET_SET_SEND";
export const BUDGET_SET_ERROR = 'BUDGET_SET_ERROR';
export const BUDGET_SET_RESPONSABLE = 'BUDGET_SET_RESPONSABLE';
export const BUDGET_CLEAR = 'BUDGET_CLEAR';