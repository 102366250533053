import React ,{useEffect} from 'react';
import {useSelector} from 'react-redux';
import LoadingImg from '../../assets/images/loading.svg';
import './Loading.scss';

const Loading = (props) => {
    let loader = null;
    const isLoading = useSelector(state => state.global.isLoading);
    useEffect(() => {
        if(isLoading) loader.focus();
    },[isLoading]);

    return (
        <div className="loading-container" >
           <div className="loading d-flex justify-content-center" >
                <div className="loadingio-spinner-eclipse-89pd4gzxl1m p-1">
                    <div className="ldio-qs9l2kov72">
                        <input ref={(ref) => loader = ref} type="hidden" />
                        <img alt="" className="position-absolute ml-2 mt-4"  id="loader-img" src={LoadingImg}/>
                        <div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Loading;
