import * as actionTypes from "../constants/action_types";
import { updateObject } from "../utilities";

const initialState = {
  policy: null,
  vehicle: null,
  contact: null,
  iva: false,
  responsable: "AgentRetentionIva",
  sendBudget: null,
  coexistence: false,
  NotVehicleError: {
    type: null,
    message : "",
  },
  errorBudget: {
    isError: false,
    message : null
  },
};

const setBudgetSend = (state, action) => {
  return updateObject(state, {
    sendBudget: action.sendBudget,
  });
};

const setBudgetResponsable = (state, action) => {
  return updateObject(state, {
    responsable: action.responsable,
  });
};

const setBudgetError = (state, action) => {
  return updateObject(state, {
    errorBudget: action.errorBudget,
  });
};

const setBudgetNotVehicleError = (state, action) => {
  return updateObject(state, {
    NotVehicleError: action.NotVehicleError,
  });
};

const setBudgetPolicy = (state, action) => {
  return updateObject(state, {
    policy: action.policy,
  });
};

const setBudgetCoexistence = (state, action) => {
  return updateObject(state, {
    coexistence: action.coexistence,
  });
};

const setBudgetIva = (state, action) => {
  return updateObject(state, {
    iva: action.iva,
  });
};

const setBudgetContact = (state, action) => {
  return updateObject(state, {
    contact: action.contact,
  });
};

const setBudgetVehicle = (state, action) => {
  return updateObject(state, {
    vehicle: action.vehicle,
  });
};

const setBudgetClear = (state, action) => {
  return {
    ...initialState,
    errorBudget: state.errorBudget,
    policy: state.policy,
  };
};

const budget = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.BUDGET_SET_POLICY:
      return setBudgetPolicy(state, action);
    case actionTypes.BUDGET_SET_CONTACT:
      return setBudgetContact(state, action);
    case actionTypes.BUDGET_SET_VEHICLE:
      return setBudgetVehicle(state, action);
    case actionTypes.BUDGET_SET_VEHICLE_ERROR:
      return setBudgetNotVehicleError(state, action);
    case actionTypes.BUDGET_SET_POLICY_NOT_FOUND_ERROR:
      return setBudgetNotVehicleError(state, action);
    case actionTypes.BUDGET_SET_IVA:
      return setBudgetIva(state, action);
    case actionTypes.BUDGET_SET_COEXISTENCE:
      return setBudgetCoexistence(state, action);
    case actionTypes.BUDGET_SET_SEND:
      return setBudgetSend(state, action);
    case actionTypes.BUDGET_CLEAR:
      return setBudgetClear(state, action);
    case actionTypes.BUDGET_SET_ERROR:
      return setBudgetError(state, action);
    case actionTypes.BUDGET_SET_RESPONSABLE:
      return setBudgetResponsable(state, action);
    default:
      return state;
  }
};

export default budget;
